import React, { Component } from "react";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import styles from "./NationalPlayer.module.css";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from "../Common";
import { withTranslation } from "react-i18next";
import { Card, Icon, Image } from "semantic-ui-react";

class NationalPlayer extends Component {
  constructor() {
    super();
    this.state = {
      maleProfiles: "",
      femaleProfiles: "",
      warningModalOpen: false,
      warningText: "",
    };
  }

  componentDidMount = () => {
    document.title = "국가대표 - 대한드론축구협회";
    this.getNationalProfileData();
  };

  getNationalProfileData = () => {
    fetch(Common.backendUrl + "/api/player/national/profile")
      .then((res) => {
        if (res.status > 400) {
          this.handleWarningModalOpen("error❗❗");
        }
        return res.json();
      })
      .then((profiles) => {
        // console.log(profiles);
        if (!profiles) return; // res.json() 결과가 없을 경우 처리 방지
        const femaleProfiles = profiles
        .filter(
          (profile) => profile.team && profile.team.team_text === "여자국가대표"
        )
        .sort((a, b) => b.id - a.id); // ID 값으로 내림차순 정렬
      
      const maleProfiles = profiles
        .filter(
          (profile) => profile.team && profile.team.team_text === "국가대표"
        )
        .sort((a, b) => b.id - a.id); // ID 값으로 내림차순 정렬
        this.setState({
          femaleProfiles,
          maleProfiles
        });
      });
  };

  handleWarningModalOpen = (text) => {
    this.setState({
      warningModalOpen: true,
      warningText: text,
    });
  };

  handleWarningModalClose = () => {
    this.setState({
      warningModalOpen: false,
    });
  };

  render() {
    const { maleProfiles, femaleProfiles } = this.state;
    const { t } = this.props;
    const crntYear = new Date().getFullYear();

    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100vw" }}>
        <Topmenu />
        <div className={styles.wrap_div}>
          <div className={styles.title_male_div}>
            <h1>{t("info_squad.NationalPlayer.Main_Male_Title")}</h1>
          </div>
          <div>
            <div className={styles.content_div}>
              {maleProfiles.length !== 0 ? (
                maleProfiles.map((profile, index) => (
                  // <Card style={{ width: "380px", marginTop: index === 0 && "1rem", display: "flex" }}>
                  //     {profile.image_file === null ? <Image src="/images/default_profile.png" alt="profile image" /> : <Image src={profile.image_file} height="200" alt="profile image" />}
                  //     <Card.Content>
                  //         <Card.Header>{profile.member_text}</Card.Header>
                  //         <Card.Description>{profile.member_position.position_text}</Card.Description>
                  //         <Card.Description>{profile.team.team_text}</Card.Description>
                  //     </Card.Content>
                  // </Card>
                  <div className={styles.card_wrap}>
                    <div className={styles.card_content}>
                      <div>
                        <p
                          className={styles.card_description}
                          style={{ marginBottom: "7px" }}
                        >
                          player
                        </p>
                        <p className={styles.card_player_name_text}>
                          {profile.member_text}
                        </p>
                      </div>
                      <div>
                        <p
                          className={styles.card_description}
                          style={{ marginBottom: "5px" }}
                        >
                          {profile.member_position.position_text}
                        </p>
                        <p className={styles.card_description}>
                          {crntYear} | 국가대표
                        </p>
                      </div>
                    </div>
                    {profile.image_file === null ? (
                      <Image
                        src="/images/default_profile.png"
                        alt="profile image"
                        width={220}
                        height={270}
                        style={{
                          border: "1px solid #0E1B37",
                          borderTopRightRadius: "4px",
                          borderBottomRightRadius: "4px",
                        }}
                      />
                    ) : (
                      <Image
                        src={profile.image_file}
                        width={220}
                        height={270}
                        alt="profile image"
                      />
                    )}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={styles.title_female_div}>
            <h1>{t("info_squad.NationalPlayer.Main_FeMale_Title")}</h1>
          </div>
          <div>
            <div className={styles.content_div}>
              {femaleProfiles.length !== 0 ? (
                femaleProfiles.map((profile, index) => (
                  // <Card style={{ width: "380px", marginTop: index === 0 && "1rem", display: "flex" }}>
                  //     {profile.image_file === null ? <Image src="/images/default_profile.png" alt="profile image" /> : <Image src={profile.image_file} height="200" alt="profile image" />}
                  //     <Card.Content>
                  //         <Card.Header>{profile.member_text}</Card.Header>
                  //         <Card.Description>{profile.member_position.position_text}</Card.Description>
                  //         <Card.Description>{profile.team.team_text}</Card.Description>
                  //     </Card.Content>
                  // </Card>
                  <div className={styles.card_wrap}>
                    <div className={styles.card_content}>
                      <div>
                        <p
                          className={styles.card_description}
                          style={{ marginBottom: "7px" }}
                        >
                          player
                        </p>
                        <p className={styles.card_player_name_text}>
                          {profile.member_text}
                        </p>
                      </div>
                      <div>
                        <p
                          className={styles.card_description}
                          style={{ marginBottom: "5px" }}
                        >
                          {profile.member_position.position_text}
                        </p>
                        <p className={styles.card_description}>
                          {crntYear} | 국가대표
                        </p>
                      </div>
                    </div>
                    {profile.image_file === null ? (
                      <Image
                        src="/images/default_profile.png"
                        alt="profile image"
                        width={220}
                        height={270}
                        style={{
                          border: "1px solid #0E1B37",
                          borderTopRightRadius: "4px",
                          borderBottomRightRadius: "4px",
                        }}
                      />
                    ) : (
                      <Image
                        src={profile.image_file}
                        width={220}
                        height={270}
                        alt="profile image"
                      />
                    )}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <ModalChoiceOne
          isOpen={this.state.warningModalOpen}
          warningText={this.state.warningText}
          closeMethod={this.handleWarningModalClose}
        />
        <Footer />
      </div>
    );
  }
}

export default withTranslation()(NationalPlayer);
