import React, { Component } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {
  Form,
  Segment,
  Input,
  Dropdown,
  Button,
  Table,
  TextArea,
  Pagination,
  Grid,
  Container,
} from "semantic-ui-react";
import styles from "./ApplyTeamRegistration.module.css";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import Common from "../Common.js";

const daum = window.daum;

class ApplyTeamRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: 0,
      selectedPage: 0,
      totalPages: 0,
      teamRegistrations: "",
      registrationCnt: 0,

      provinceOptions: "",
      regionOptions: "",
      provinceId: "",
      regionId: "",
      teamName: "",
      membership: "",
      regDate: "",
      leagueOptions: "",
      leagueId: "",
      address: "",
      applicantName: "",
      image_file: "",
      warningModalOpen: false,
      warningText: "",
      warningLocation: false,
      teamNameDupCheck: false,
      leftSelectedMenu: "선수단 창단 신청",
      searchLists: "",
    };
  }

  componentDidMount = () => {
    // window.scrollTo(0, 0);
    this.getProvinceList();
    this.getTeamRegistrationData(0, 1);
    // this.getLeagueList()
  };

  getProvinceList() {
    fetch(Common.backendUrl + "/api/province/")
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        // this.setState({ provinceData: data });
        let provinceOptions = [];
        data.forEach((province) =>
          provinceOptions.push({
            key: province.id,
            text: province.province_text,
            value: province.id,
          })
        );
        this.setState({ provinceOptions });
      });
  }

  getRegionData(province_id) {
    fetch(Common.backendUrl + "/api/region/" + province_id)
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        let regionOptions = [];

        data.forEach((region) =>
          regionOptions.push({
            key: region.id,
            text: region.region_text,
            value: region.id,
          })
        );

        this.setState({
          regionOptions,
        });
      });
  }

  handleProvinceChange = (e, { value }) => {
    this.setState({ provinceId: value });
    this.getRegionData(value);
  };

  handleRegionChange = (e, { value }) => {
    this.setState({ regionId: value });
  };

  handleTeamChange = (e, { value }) => {
    this.setState({ teamName: value, teamNameDupCheck: false });
  };

  dateHandleChange = (event, data) => {
    const checkDate = data.value;
    let strDate = "";
    let month = "";
    let date = "";
    if (checkDate !== null) {
      if (checkDate.getMonth() < 10) {
        month = "0" + (checkDate.getMonth() + 1);
      } else {
        month = checkDate.getMonth() + 1;
      }

      if (checkDate.getDate() < 10) {
        date = "0" + checkDate.getDate();
      } else {
        date = checkDate.getDate();
      }

      this.setState({
        regDate: strDate,
      });
    }
  };

  searchAddr(objStr) {
    new daum.Postcode({
      oncomplete: function (data) {
        var addr = ""; // 주소 변수
        var extraAddr = ""; // 참고항목 변수

        //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
        if (data.userSelectedType === "R") {
          // 사용자가 도로명 주소를 선택했을 경우
          addr = data.roadAddress;
        } else {
          // 사용자가 지번 주소를 선택했을 경우(J)
          addr = data.jibunAddress;
        }

        // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
        if (data.userSelectedType === "R") {
          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
            extraAddr += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== "" && data.apartment === "Y") {
            extraAddr +=
              extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
          }
          // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraAddr !== "") {
            extraAddr = " (" + extraAddr + ")";
          }
          // 조합된 참고항목을 해당 필드에 넣는다.
        } else {
        }
        // document.getElementById(objStr).value = "(" + data.zonecode + ") " + addr + " " + extraAddr;
        // this.setState({ address: "(" + data.zonecode + ") " + addr + " " + extraAddr })
        document.getElementById(objStr).value =
          "(" + data.zonecode + ") " + addr + " " + extraAddr;
        // 우편번호와 주소 정보를 해당 필드에 넣는다.
      }.bind(this),
    }).open();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let regExp =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

    if (this.state.provinceId === "") {
      this.setState({
        warningModalOpen: true,
        warningText: "지회를 선택해주세요.",
      });
      return;
    } else if (this.state.regionId === "") {
      this.setState({
        warningModalOpen: true,
        warningText: "지부를 선택해주세요.",
      });
      return;
    } else if (document.getElementById("membership").innerText === "회원구분") {
      this.setState({
        warningModalOpen: true,
        warningText: "회원구분을 선택해주세요.",
      });
      return;
    } else if (
      document.getElementById("league_text").innerText === "리그를 선택하세요"
    ) {
      this.setState({
        warningModalOpen: true,
        warningText: "리그를 선택해주세요.",
      });
      return;
    } else if (this.state.teamNameDupCheck == false) {
      this.setState({
        warningModalOpen: true,
        warningText: "선수단명 중복 확인을 해주세요.",
      });
      return;
    } else if (
      regExp.test(document.getElementById("applicantEmailInput").value) ===
      false
    ) {
      this.setState({
        warningModalOpen: true,
        warningText: "잘못된 이메일입니다.",
      });
      return;
    }
    let form_data = new FormData();
    form_data.append("province", this.state.provinceId);
    form_data.append("region", this.state.regionId);
    form_data.append("team_name", document.getElementById("team_text").value);
    if (document.getElementById("membership").innerText === "정회원") {
      form_data.append("membership", 1);
    } else if (document.getElementById("membership").innerText === "일반회원") {
      form_data.append("membership", 0);
    }

    if (document.getElementById("league_text").innerText === "세미프로") {
      form_data.append("league_text", 1);
    } else if (document.getElementById("league_text").innerText === "마스터즈") {
      form_data.append("league_text", 2);
    } else if (document.getElementById("league_text").innerText === "챌린저스") {
      form_data.append("league_text", 3);
    } else if (
      document.getElementById("league_text").innerText === "유소년 리그"
    ) {
      form_data.append("league_text", 4);
    }
    // form_data.append('league_text', this.state.leagueId)
    form_data.append("address", document.getElementById("address").value);
    form_data.append(
      "address_detail",
      document.getElementById("address_detail").value
    );
    form_data.append(
      "applicant_name",
      document.getElementById("applicantNameInput").value
    );
    form_data.append(
      "team_phone",
      document.getElementById("teamPhoneNum").value
    );
    form_data.append(
      "applicant_email",
      document.getElementById("applicantEmailInput").value
    );

    // 값이 있을때만 넣어 줄 값들
    if (this.state.regDate.length !== 0) {
      form_data.append("reg_date", this.state.regDate);
    }

    if (document.getElementById("info_text").value.length !== 0) {
      form_data.append("info_text", document.getElementById("info_text").value);
    }
    if (this.state.image_file !== "") {
      form_data.append("team_image", this.state.image_file.name);
      form_data.append("team_image_file", this.state.image_file);
    }

    fetch(Common.backendUrl + "/api/teamregistration/create/", {
      method: "post",
      body: form_data,
    })
      .then((response) => {
        if (response.status >= 400) {
          return response.json();
        } else {
          return 200;
        }
      })
      .then((json) => {
        if (json != undefined) {
          if (json === 200) {
            this.setState({
              warningModalOpen: true,
              warningText: "선수단 신청 완료",
              warningLocation: true,
            });
            return;
          }
          this.setState({
            warningModalOpen: true,
            warningText: json.detail,
          });
        } else {
          this.setState({
            warningModalOpen: true,
            warningText: "error",
          });
        }
      });
  };

  handleTeamSearch = (e) => {
    e.preventDefault();

    if (document.getElementById("searchTeamName").value === "") {
      this.setState({
        warningModalOpen: true,
        warningText: "선수단명을 입력하세요",
      });
      return;
    } else if (document.getElementById("searchName").value === "") {
      this.setState({
        warningModalOpen: true,
        warningText: "신청자명을 입력하세요",
      });
      return;
    }

    fetch(
      Common.backendUrl +
        `/api/teamregistration/search/list/${
          document.getElementById("searchTeamName").value
        }/${document.getElementById("searchName").value}`
    )
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          searchLists: data,
          teamRegistrations: "",
          registrationCnt: 0,
        });
      });
  };

  getTeamRegistrationData = (option, page) => {
    // fetch(Common.backendUrl + `/api/team/requests/manage/${option}?page=${page}`)
    fetch(Common.backendUrl + `/api/teamregistration/list/all?page=${page}`)
      .then((res) => {
        if (res.status >= 400) {
          this.setState({
            warningModalOpen: true,
            warningText: "error",
          });
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (data["count"] % 10 != 0) {
          this.setState({
            totalPages: Math.floor(data["count"] / 10) + 1,
          });
        } else {
          this.setState({
            totalPages: Math.floor(data["count"] / 10),
          });
        }
        this.setState({
          teamRegistrations: data.results,
          registrationCnt: data.count,
          searchLists: "",
        });
      });
  };

  navOnClick = (e, page) => {
    this.setState({
      selectedPage: page,
    });
    this.getTeamRegistrationData(this.state.selectedOption, page);
  };

  teamNameDuplicateCheck = (e) => {
    e.preventDefault();

    // \는 체크 안됨
    const pattern_spc = /[~!@#$%^&`*()_=+<>/?₩,.;:|[{}]/;
    // console.log(pattern_spc.test(document.getElementById("team_text").value));

    if (document.getElementById("team_text").value === "") {
      this.setState({
        warningModalOpen: true,
        warningText: "선수단명을 작성해주세요",
      });
      return;
    } else if (document.getElementById("team_text").value.search(/\s/) != -1) {
      this.setState({
        warningModalOpen: true,
        warningText: "선수단명 공백을 제거해주세요.",
      });
      return;
    } else if (document.getElementById("team_text").value.length > 10) {
      this.setState({
        warningModalOpen: true,
        warningText: "10자 이내의 선수단명을 입력해주세요.",
      });
      return;
    } else if (pattern_spc.test(document.getElementById("team_text").value)) {
      this.setState({
        warningModalOpen: true,
        warningText: "'-' 를 제외한 특수문자는 입력할 수 없습니다.",
      });
      return;
    } else {
      fetch(
        Common.backendUrl +
          `/api/teamregistration/check/duplicate1/${
            document.getElementById("team_text").value
          }`
      )
        .then((response) => {
          if (response.status > 400) {
            return this.setState(() => {
              return { placeholder: "Something went wrong!" };
            });
          }
          return response.json();
        })
        .then((data) => {
          if (data === undefined) {
            this.setState({
              warningModalOpen: true,
              warningText: "잘못된 선수단명입니다.",
              teamNameDupCheck: false,
            });
            return;
          }
          if (data.length === 0) {
            this.setState({
              warningModalOpen: true,
              warningText: "사용 가능한 선수단명입니다.",
              teamNameDupCheck: true,
            });
            // this.teamNameDuplicateCheck2(document.getElementById('team_text').value)
          } else {
            this.setState({
              warningModalOpen: true,
              warningText: "이미 사용중인 선수단명입니다.",
              teamNameDupCheck: false,
            });
          }
        });
    }
  };

  // teamNameDuplicateCheck2 = (team_text) => {
  //     fetch(Common.backendUrl + `/api/teamregistration/check/duplicate2/${team_text}`)
  //     .then(response => {
  //         if (response.status > 400) {
  //             return this.setState(() => {
  //                 return { placeholder: "Something went wrong!" };
  //             });
  //         }
  //                 return response.json();
  //             }) .then(data => {
  //                 if (data.length === 0) {
  //                     this.setState({
  //                         warningModalOpen: true,
  //                         warningText: "사용 가능한 선수단명입니다.",
  //                         teamNameDupCheck: true,
  //                     })
  //                 } else {
  //                     this.setState({
  //                         warningModalOpen: true,
  //                         warningText: "이미 사용중인 선수단명입니다.",
  //                         teamNameDupCheck: false,
  //                     })
  //                 }
  //             })
  // }

  handleWarningModalClose = () => {
    this.setState({
      warningModalOpen: false,
    });

    if (this.state.warningLocation) {
      window.location.href = "/";
      // window.location.reload();
    }
  };

  render() {
    const { searchLists, teamRegistrations, registrationCnt } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Topmenu />
        <div className={styles.wrap_div}>
          <div
            style={{
              position: "sticky",
              left: "10px",
              top: "10%",
              width: "30%",
              maxWidth: "200px",
              marginRight: "30px",
              height: "max-content",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <div className={styles.menu_div}>
              <>
                <h1>선수단 창단 신청</h1>
                <hr />
                <p
                  id="선수단 창단 신청"
                  onClick={() =>
                    this.setState({ leftSelectedMenu: "선수단 창단 신청" })
                  }
                >
                  선수단 창단 신청
                </p>
                <p
                  id="신청현황확인"
                  onClick={() =>
                    this.setState({ leftSelectedMenu: "신청현황확인" })
                  }
                >
                  신청현황확인
                </p>
              </>
            </div>
          </div>
          <div className={styles.content_div}>
            {this.state.leftSelectedMenu === "선수단 창단 신청" ? (
              <Segment.Group
                style={{
                  width: "80%",
                  maxWidth: "800px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #000000",
                }}
              >
                <Segment
                  textAlign="center"
                  style={{
                    fontFamily: "Noto Sans KR",
                    fontSize: "20px",
                    fontWeight: "bold",
                    backgroundColor: "inherit",
                  }}
                >
                  선수단 창단 신청
                </Segment>
                <p style={{ width: "90%", textAlign: "right" }}>
                  <spen
                    style={{
                      color: "#db2828",
                      margin: "-.2em 0 0 .2em",
                      fontSize: "larger",
                    }}
                  >
                    *
                  </spen>
                  는 필수항목입니다.
                </p>

                <Segment.Group
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "90%",
                    padding: "20px",
                    margin: "0px",
                    border: "1px solid #000000",
                  }}
                >
                  <Form
                    onSubmit={this.handleSubmit}
                    style={{ width: "90%", fontFamily: "Noto Sans KR" }}
                  >
                    <Form.Field required>
                      <label className="label">지회</label>
                      <Dropdown
                        style={{ border: "1px solid #000000" }}
                        placeholder="지회 선택"
                        selection
                        options={this.state.provinceOptions}
                        name="province"
                        onChange={this.handleProvinceChange}
                        fluid
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label className="label">지부</label>
                      <Dropdown
                        style={{ border: "1px solid #000000" }}
                        placeholder="지부 선택"
                        selection
                        options={this.state.regionOptions}
                        name="region_text"
                        onChange={this.handleRegionChange}
                        fluid
                        in
                      />
                    </Form.Field>
                    <Form.Field required style={{ margin: "0px" }}>
                      <div>
                        <label
                          style={{
                            display: "inline-block",
                            margin: "0 0 .28571429rem 0",
                            color: "rgba(0,0,0,.87)",
                            fontSize: ".92857143em",
                            fontWeight: "700",
                            textTransform: "none",
                          }}
                        >
                          선수단명
                          <span
                            style={{ color: "#db2828", fontWeight: "bold" }}
                          >
                            *
                          </span>
                        </label>
                        <Button
                          color="blue"
                          inverted
                          size="tiny"
                          style={{
                            marginLeft: "10px",
                            padding: "5px",
                            fontFamily: "Noto Sans KR",
                          }}
                          onClick={(e) => this.teamNameDuplicateCheck(e)}
                        >
                          중복 확인
                        </Button>
                      </div>
                      <Input
                        style={{
                          border: "1px solid #000000",
                          borderRadius: "5px",
                        }}
                        type="text"
                        name="team_text"
                        id="team_text"
                        placeholder="공백 없이 10자 이내로 입력하세요."
                        onChange={() =>
                          this.setState({ teamNameDupCheck: false })
                        }
                        required
                      />
                    </Form.Field>
                    <label style={{ display: "block" }}>
                      ※ 선수단명에 드론축구단, 선수단 등의 단어입력 불필요 (예:
                      전주시 드론축구단 -&gt; 전주시)
                    </label>
                    <label style={{ display: "block", margin: "0 0 1em" }}>
                      ※ 시 공식 창단 선수단만 "시" 명칭 사용 가능 (예:
                      과천시드론축구단 -&gt; 과천)
                    </label>
                    <Form.Field required style={{ margin: "0px" }}>
                      <label className="label">회원구분</label>
                      <Dropdown
                        style={{ border: "1px solid #000000" }}
                        placeholder="회원구분"
                        selection
                        options={[
                          { key: 0, text: "일반회원", value: 0 },
                          { key: 1, text: "정회원", value: 1 },
                        ]}
                        name="membership"
                        id="membership"
                        fluid
                      />
                    </Form.Field>
                    <label style={{ display: "block", margin: "0 0 1em" }}>
                      ※ 정회원은 협회에 가입비와 연회비가 납부되어 있어야 승인이
                      가능합니다.
                    </label>
                    <Form.Field>
                      <label className="label">팀창단일</label>
                      <SemanticDatepicker
                        className={styles.datepicker_input}
                        name="reg_date"
                        onChange={this.dateHandleChange}
                      />
                    </Form.Field>

                    <Form.Field required style={{ margin: "0px" }}>
                      <label className="label">리그</label>
                      <Dropdown
                        style={{ border: "1px solid #000000" }}
                        placeholder="리그를 선택하세요"
                        selection
                        // options={this.state.leagueOptions}
                        options={[
                          { key: 0, text: "세미프로", value: 1 },
                          { key: 1, text: "마스터즈", value: 2 },
                          { key: 2, text: "챌린저스", value: 3 },
                          { key: 3, text: "유소년 리그", value: 4 },
                        ]}
                        name="league_text"
                        id="league_text"
                        fluid
                      />
                    </Form.Field>
                    <label style={{ display: "block", margin: "0 0 1em" }}>
                      ※ 유소년 리그 외 신규팀은 챌린저스로 입력하세요.
                    </label>
                    <Form.Field required style={{ margin: "0px" }}>
                      <label className="label">대표주소</label>
                      <Input
                        style={{
                          margin: "0 0 0.1em",
                          border: "1px solid #000000",
                          borderRadius: "5px",
                        }}
                        type="text"
                        name="address"
                        id="address"
                        placeholder="대표주소를 입력해주세요."
                        // onChange={(e, { value }) => this.setState({ address: value })}
                        onClick={function (e) {
                          this.searchAddr("address");
                        }.bind(this)}
                        required
                      />
                      <Input
                        style={{
                          border: "1px solid #000000",
                          borderRadius: "5px",
                        }}
                        type="text"
                        name="address_detail"
                        id="address_detail"
                        placeholder="상세주소를 입력해주세요."
                        required
                      />
                    </Form.Field>
                    <label style={{ display: "block", margin: "0 0 1em" }}>
                      ※ 우편물 수령이 가능한 주소를 입력하세요.
                    </label>
                    <Form.Field required style={{ margin: "0px" }}>
                      <label>선수단장 정보</label>
                      <span
                        style={{
                          fontSize: "13px",
                          fontFamily: "Noto Sans KR",
                          fontWeight: "400",
                          lineHeight: "1.4285em",
                        }}
                      >
                        이름
                      </span>
                      <Input
                        style={{
                          border: "1px solid #000000",
                          borderRadius: "5px",
                        }}
                        id="applicantNameInput"
                        placeholder="이름을 입력하세요"
                        type="text"
                        name="name"
                        // onChange={(e, {value}) => this.setState({applicantName: value})}
                        required
                      />
                      <span
                        style={{
                          fontSize: "13px",
                          fontFamily: "Noto Sans KR",
                          fontWeight: "400",
                          lineHeight: "1.4285em",
                        }}
                      >
                        이메일
                      </span>
                      <Input
                        style={{
                          border: "1px solid #000000",
                          borderRadius: "5px",
                        }}
                        id="applicantEmailInput"
                        placeholder="dronesoccer@naver.com"
                        type="text"
                        name="email"
                        required
                      />
                      <span
                        style={{
                          fontSize: "13px",
                          fontFamily: "Noto Sans KR",
                          fontWeight: "400",
                          lineHeight: "1.4285em",
                        }}
                      >
                        전화번호
                      </span>
                      <Input
                        style={{
                          border: "1px solid #000000",
                          borderRadius: "5px",
                        }}
                        id="teamPhoneNum"
                        placeholder="-을 제외한 11자리 번호입력"
                        type="text"
                        name="phone"
                        required
                      />
                    </Form.Field>
                    <label style={{ display: "block", margin: "0 0 1em" }}>
                      ※ 창단신청 승인/반려시 안내메일이 발송됩니다. 정확한
                      정보를 입력하세요.
                    </label>
                    <Form.Field>
                      <label className="label">
                        팀 로고 이미지 (500x500 ~ 800x800 사이즈)
                      </label>
                      <Input
                        style={{
                          overflow: "hidden",
                          border: "1px solid #000000",
                        }}
                        type="file"
                        name="profile"
                        onChange={(e) =>
                          this.setState({ image_file: e.target.files[0] })
                        }
                        accept="image/png, image/jpeg"
                      />
                    </Form.Field>
                    <Form.Field>
                      <label className="label">선수단 소개</label>
                      <textarea
                        style={{
                          resize: "none",
                          height: "60px",
                          border: "1px solid #000000",
                        }}
                        id="info_text"
                        placeholder="선수단을 자유롭게 소개해주세요."
                      />
                    </Form.Field>
                    {/* <Button id="realApplyBtn" type="submit" positive className="button is-info" style={{display: "none"}}></Button> */}
                    <Button
                      id="realApplyBtn"
                      positive
                      className="button is-info"
                      style={{ display: "none" }}
                    ></Button>
                  </Form>
                </Segment.Group>
                <Button
                  positive
                  className="button is-info"
                  style={{ margin: "15px" }}
                  onClick={() =>
                    document.getElementById("realApplyBtn").click()
                  }
                >
                  신청하기
                </Button>
              </Segment.Group>
            ) : (
              <></>
            )}
            {this.state.leftSelectedMenu === "신청현황확인" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: "1200px",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  선수단 창단 신청현황확인
                </p>
                <div
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Input
                    id="searchTeamName"
                    placeholder="선수단명을 입력하세요"
                    style={{
                      width: "200px",
                      border: "1px solid #000000",
                      borderRadius: "5px",
                    }}
                  />
                  <Input
                    id="searchName"
                    placeholder="신청자명을 입력하세요"
                    style={{
                      width: "200px",
                      margin: "0px 10px",
                      border: "1px solid #000000",
                      borderRadius: "5px",
                    }}
                  />
                  <Button primary onClick={(e) => this.handleTeamSearch(e)}>
                    검색
                  </Button>
                  <Button
                    primary
                    onClick={(e) => this.getTeamRegistrationData(0, 1)}
                  >
                    목록
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Table
                    striped
                    unstackable
                    style={{ border: "1px solid #000000" }}
                  >
                    <Table.Header
                      style={{
                        display: "table",
                        width: "100%",
                        tableLayout: "fixed",
                      }}
                    >
                      <Table.Row style={{ width: "100%" }}>
                        <Table.HeaderCell
                          style={{ borderBottom: "1px solid #000000" }}
                          width="1"
                          textAlign="center"
                        >
                          신청자
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{ borderBottom: "1px solid #000000" }}
                          width="1"
                          textAlign="center"
                        >
                          선수단명
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{ borderBottom: "1px solid #000000" }}
                          width="1"
                          textAlign="center"
                        >
                          신청일자
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{ borderBottom: "1px solid #000000" }}
                          width="1"
                          textAlign="center"
                        >
                          처리상태
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body
                      style={{
                        display: "block",
                        height: "200px",
                        overflow: "auto",
                      }}
                    >
                      {searchLists.length !== 0 ? (
                        searchLists.map((list) => (
                          <Table.Row
                            key={list.id}
                            style={{
                              display: "table",
                              width: "100%",
                              tableLayout: "fixed",
                            }}
                          >
                            <Table.Cell width="1" textAlign="center">
                              {list.applicant_name}
                            </Table.Cell>
                            <Table.Cell width="1" textAlign="center">
                              {list.team_name}
                            </Table.Cell>
                            <Table.Cell width="1" textAlign="center">
                              {list.create_at}
                            </Table.Cell>
                            <Table.Cell width="1" textAlign="center">
                              {list.status === 0
                                ? "신청"
                                : list.status === 1
                                ? "승인"
                                : list.status === 2
                                ? "반려"
                                : ""}
                            </Table.Cell>
                          </Table.Row>
                        ))
                      ) : // 검색하기 전 상태 놓기
                      teamRegistrations && registrationCnt != 0 ? (
                        teamRegistrations.map((registration, index) =>
                          index === teamRegistrations.length - 1 ? (
                            <Table.Row
                              key={registration.id}
                              style={{
                                display: "table",
                                width: "100%",
                                tableLayout: "fixed",
                              }}
                            >
                              <Table.Cell width="1" textAlign="center">
                                {registration.applicant_name[0] +
                                  "*".repeat(
                                    registration.applicant_name.length - 1
                                  )}
                              </Table.Cell>
                              <Table.Cell width="1" textAlign="center">
                                {registration.team_name}
                              </Table.Cell>
                              <Table.Cell width="1" textAlign="center">
                                {registration.create_at}
                              </Table.Cell>
                              <Table.Cell width="1" textAlign="center">
                                {registration.status === 0
                                  ? "신청"
                                  : registration.status === 1
                                  ? "승인"
                                  : registration.status === 2
                                  ? "반려"
                                  : ""}
                              </Table.Cell>
                            </Table.Row>
                          ) : (
                            <Table.Row
                              key={registration.id}
                              style={{
                                display: "table",
                                width: "100%",
                                tableLayout: "fixed",
                              }}
                            >
                              <Table.Cell
                                style={{ borderBottom: "1px solid #000000" }}
                                width="1"
                                textAlign="center"
                              >
                                {registration.applicant_name[0] +
                                  "*".repeat(
                                    registration.applicant_name.length - 1
                                  )}
                              </Table.Cell>
                              <Table.Cell
                                style={{ borderBottom: "1px solid #000000" }}
                                width="1"
                                textAlign="center"
                              >
                                {registration.team_name}
                              </Table.Cell>
                              <Table.Cell
                                style={{ borderBottom: "1px solid #000000" }}
                                width="1"
                                textAlign="center"
                              >
                                {registration.create_at}
                              </Table.Cell>
                              <Table.Cell
                                style={{ borderBottom: "1px solid #000000" }}
                                width="1"
                                textAlign="center"
                              >
                                {registration.status === 0
                                  ? "신청"
                                  : registration.status === 1
                                  ? "승인"
                                  : registration.status === 2
                                  ? "반려"
                                  : ""}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )
                      ) : (
                        <Table.Row>
                          <Table.Cell
                            textAlign="center"
                            colSpan="6"
                            style={{ fontSize: "15px" }}
                          >
                            요청사항이 없습니다.
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                  {registrationCnt != 0 ? (
                    <Pagination
                      style={{ marginTop: "10px" }}
                      boundaryRange={1}
                      defaultActivePage={1}
                      ellipsisItem={null}
                      firstItem={null}
                      lastItem={null}
                      siblingRange={2}
                      totalPages={this.state.totalPages}
                      onPageChange={(e, data) =>
                        this.navOnClick(e, data.activePage)
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <ModalChoiceOne
                  isOpen={this.state.warningModalOpen}
                  warningText={this.state.warningText}
                  closeMethod={this.handleWarningModalClose}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <ModalChoiceOne
            isOpen={this.state.warningModalOpen}
            warningText={this.state.warningText}
            closeMethod={this.handleWarningModalClose}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default ApplyTeamRegistration;
