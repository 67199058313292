import React, { Component } from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import TeamDSPointRank from "./TeamDSPointRank.js";
import TeamWinCountRank from "./TeamWinCountRank.js";
import TeamParticipationCountRank from "./TeamParticipationCountRank.js";
import RankTypeTab from "./RankTypeTab.js";
import LeagueTab from "./LeagueTab.js";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import styles from "./RankTeam.module.css";
import Common from "../Common";
import { withTranslation } from "react-i18next";

class RankTeam extends Component {
    constructor(props) {
        const maxYear = new Date().getFullYear();
        const minYear = 2020;
        const years = [];

        for (let i = maxYear; i >= minYear; i--) {
            years.push({ key: i, text: i, value: i });
        }

        super(props);
        this.state = {
            negTeamList: "",
            rankType: 1,
            leagueId: 1,
            competitions: [],
            crntYear: new Date().getFullYear(),
            yearDropDownOptions: years,
            radioGroup: {
                dsPoint: true,
                winCount: false,
                participationCount: false,
            },
        };
        this.handleRadio = this.handleRadio.bind(this);
    }

    componentDidMount = () => {
        document.title = "팀랭킹 - 대한드론축구협회";
    };

    selRankTpyeHandler(selNum) {
        this.setState({ rankType: selNum });
    }

    selLeagueHandler(selNum) {
        this.setState({ leagueId: selNum });
    }

    yearDropdownChange = (e, { value }) => {
        this.setState({
            crntYear: value,
        });
    };

    getNotJoinTeam() {
        fetch(Common.backendUrl + "/api/team/not_join_rank/" + this.state.crntYear + "/" + this.state.leagueId)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ negTeamList: data });
            });
    }

    handleRadio(event) {
        let obj = {};
        obj[event.target.value] = event.target.checked; // true

        if (event.target.value === "dsPoint") {
            this.setState({ rankType: 1 });
        }

        if (event.target.value === "winCount") {
            this.setState({ rankType: 2 });
        }

        if (event.target.value === "participationCount") {
            this.setState({ rankType: 3 });
        }

        this.setState({ radioGroup: obj });
    }

    render() {
        window.scrollTo(0, 0);
        const { t } = this.props;

        return (
            <>
                <Topmenu />
                <div className={styles.rank_div}>
                    <div className={styles.rank_wrap}>
                        {this.state.leagueId <= 3 && (
                            <h1 style={{ textAlign: "center", marginBottom: "50px", fontSize: "56px" }}>
                                <>
                                { this.state.leagueId === 1 &&
                                t("Topmenu.Trigger.Rank.DropDown.Team_1st")
                                }
                                { this.state.leagueId === 2 &&
                                t("Topmenu.Trigger.Rank.DropDown.Team_2nd")
                                }
                                { this.state.leagueId === 3 &&
                                t("Topmenu.Trigger.Rank.DropDown.Team_3rd")
                                }
                                </>
                                {t("rank.RankTeam.Team_Ranking")}
                            </h1>
                        )}
                        {this.state.leagueId === 4 && <h1 style={{ marginBottom: "50px", fontSize: "56px" }}>{t("rank.RankTeam.Youth_Team_Ranking")}</h1>}
                        <div className={styles.competition_dropdown}>
                            <div>
                                <Dropdown style={{ border: "1px solid #000000" }} onChange={this.yearDropdownChange} value={this.state.crntYear} options={this.state.yearDropDownOptions} selection placeholder="대회연도" />
                            </div>
                        </div>
                        <div className={styles.rankTypeWrap}>
                            <LeagueTab onSelLeague={(selNum) => this.selLeagueHandler(selNum)} />
                        </div>
                        <div className={styles.rankTypeWrap}>
                            <RankTypeTab onSelRankType={(selNum) => this.selRankTpyeHandler(selNum)} />
                        </div>
                        {this.state.rankType === 1 && (
                            <Grid>
                                <Grid.Row centered>
                                    <TeamDSPointRank crntYear={this.state.crntYear} league={this.state.leagueId} />
                                </Grid.Row>
                            </Grid>
                        )}

                        {this.state.rankType === 2 && (
                            <Grid>
                                <Grid.Row centered>
                                    <TeamParticipationCountRank crntYear={this.state.crntYear} league={this.state.leagueId} />
                                </Grid.Row>
                            </Grid>
                        )}
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default withTranslation()(RankTeam);
